import React from 'react';
import SectionTitle from "../../SectionTitles/SectionTitle";

// About Image
import aboutImg from '../../../assets/images/blog11.jpg';

const AboutContent = () => {

  return (

    <section className="about-section" id="about">
      <div className="container">
        {/* Start: Heading */}
        <SectionTitle
          title=" Présentation "
        />
        <div className="row">
          <div className="col-md-7 col-sm-12 about_bottom_warp">
            <div className="about_list">
              <p>
              iNOV TECHNOLOGIES est un éditeur de logiciels avec une expérience avérée dans le domaine de l’intégration des données, du big data ainsi que le conseil en stratégie numérique. 
              Elle s’est développée également autour de projets porteurs dans le domaine de la qualification et la valorisation des données, issus du monde de la recherche et de l'innovation.<br />
              Nos points forts résident dans la présence d’équipes multi-compétentes qui allient de solides compétences techniques et une forte compréhension de l’évolution des usages des nouvelles technologies. Ces leviers nous permettent de garantir le meilleur compromis entre l’innovation et la maîtrise du cycle de vie des données de l’entreprise.
              </p>

            </div>


            <div className="row about_list_warp">


            </div>
          </div>

          {  /*Start: About Image  */}
          <div className="col-md-5 col-sm-12">

            { /*  About Right Image  */}
            <img src={aboutImg} alt="" />

          </div>
          {/*End: About Image  */}


        </div>
        {/* row */}
      </div>
      {/* container */}
    </section>


  );
};

export default AboutContent;
