import React from 'react';
import ServicesData from '../../../data/Services/offre-one'
import ServiceItem from "./ServiceItem";
import SectionTitle from "../../../components/SectionTitles/SectionTitle";

const Service = () => {
    return (

        <section className="service_section" id="agenda">
            <div className="container">

                <SectionTitle
                    title="Offres"
                />

                {
                    ServicesData.map(service => (
                        <div key={service.id}>
                            <ServiceItem
                                key={service.id}
                                id={service.id}
                                title={service.title}
                            />
                        </div>
                    ))
                }
            </div>
        </section>
    );
}
    ;

export default Service;