import React, { Fragment } from "react"; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";  

const blogPage = () => {
	return (
	  <Fragment>
		<MetaTags>
		  <title>iNOV Technologies| Solutions</title>
		  <meta
			name="description"
			content="iNOV Technologies"
		  />
		</MetaTags>
		<LayoutOne>

			
		</LayoutOne>
	  </Fragment>
	);
  };
  
  export default blogPage;



