import React from 'react'; 
import ContactInfo from './ContactInfo'
import Form from './Form'
import SectionTitle from "../SectionTitles/SectionTitle";

const ContactForm = () => {
    

    return (
        <div className="contact-section" id="contact">
            <div className="container"> 
                {/* Heading */}
                <SectionTitle
                  title="Contactez Nous" 
                />
                <div className="row inner-contact"> 
                <div className="col-md-12 col-sm-12 " style ={{marginBottom: '10px'}}> 
            
            <iframe title ='maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.2934226103916!2d2.3232199156748363!3d48.87168267928875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e3482a3a6fd%3A0xa37f564710c057eb!2s11%20Rue%20Tronchet%2C%2075008%20Paris%2C%20France!5e0!3m2!1sfr!2stn!4v1611310777133!5m2!1sfr!2stn" className ="col-md-12 col-sm-12" width="1000" height="300" frameborder="0" style={{border:'0'}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
           </div>
                      <div className="col-sm-12 contact_info">
                          <ContactInfo />
                      
                      </div>
 
                      <div className="col-sm-12">
                          <Form />
                      </div>
                      
                </div> 
            </div> 
        </div>
        

    );
}

export default ContactForm;