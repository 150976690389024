import React, { Fragment } from 'react';
import SectionTitle from "../../SectionTitles/SectionTitle";
import PageHeader from '../../../components/PageHeader'
import Produit2 from '../../../assets/images/smart-data.jpg'
const Service = () => {
    return (
        <Fragment>
        <PageHeader
                bgImg={require('../../../assets/images/header-background-1.jpg')}
                parentMenu=" Nos Produits   " 
                parentMenuLink = "produits"
                
            />

        <section className="about-section" id="about">
            <div className="container">
                <SectionTitle
                    title={'Smart data clean'}
                />
                <div className="row">
                    <div className="col-md-8 col-sm-12 about_bottom_warp">
                        <div className="about_list">

                        <p>Ce projet propose un outil de qualification et d’intégration des données d’entreprise. Il propose des méthodes innovantes issues de la R&D pour la qualification de données d’entreprise lui permettant d’accéder, intégrer, migrer et consolider les données générées et utilisées par l’ensemble des systèmes de l’entreprise, de ses collaborateurs et ses partenaires. Le but est de pouvoir proposer l’outil aux utilisateurs de tout niveau technique leur permettant de nettoyer et de qualifier les données avant de procéder à leur intégration.</p>
                        </div>

                    </div>
                    <div className="col-md-4 col-sm-12">

                        <img src={Produit2} alt="" />
                    </div>
                </div>
            </div>
        </section>
    </Fragment>
      
    );
}
    
export default Service;