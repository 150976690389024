import React from 'react'; 
import {Link} from  "react-router-dom"
 
const WhyChooseItem = (props) => {
	const serviceURL = `/${props.title}`
    return (
        
        <div className="why_chs_box col-md-6 col-sm-12"> 
			<span className="why_chs_icon"><i className={`${props.icon}`}></i></span>
			<Link to={`${process.env.PUBLIC_URL + serviceURL }`}>
                    <h5>{props.title}</h5>
            </Link> 
			{/* <h5>{props.title}</h5> */}
			<div className="why_chs_desc">
				<p>{props.text}</p>
			</div> 
        </div>  

    );
}

export default WhyChooseItem;