import React, { Fragment } from "react"; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne"; 
import Services from '../components/Services1/home-three' 


const servicePage = () => {
	return (
	  <Fragment>
		<MetaTags>
		  <title>iNOV Technologies</title>
		  <meta
			name="description"
			content="iNOV Technologies"
		  />
		</MetaTags>
		<LayoutOne>
		<div className="service-page">
		
			<Services /> 
		</div>
		</LayoutOne>
	  </Fragment>
	);
  };
  
  export default servicePage;