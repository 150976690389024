import React, { Fragment } from "react";
import ServicesData from '../../data/Services/offre-one';
import PageWrapper from "../../components/PageWrapper";
import ServiceContent from "./Offre1Content";

const OffreSingle = () => {
	const serviceID = new URLSearchParams(window.location.search).get("id");
	const service = ServicesData.find((serviceItem) => serviceItem.id === parseInt(serviceID));

	return (
		<Fragment>

			
			<PageWrapper classes="single_service_section">
				<ServiceContent
					service={service}
				/>
			</PageWrapper>

		</Fragment>
	);
};
export default OffreSingle;



