import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Team from '../components/Team/home-one1' 

const servicePage = () => {
	return (
		<Fragment>
			<MetaTags>
				<title>iNOV Technologies</title>
				<meta
					name="description"
					content="iNOV Technologies"
				/>
			</MetaTags>
			<LayoutOne>
				<div className="service-page">
					<Team />
				</div>
			</LayoutOne>
		</Fragment>
	);
};

export default servicePage;



