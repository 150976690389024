import React from 'react';
import SectionTitle from "../../SectionTitles/SectionTitle";
import TeamList from './TeamList'
import teamData from '../../../data/WhyChooseUs/why-choose'

function Services({ classes }) {
    return (
        <section className={`team_section ${classes}`} id="team">
            <div className="container">
                {/* Heading */}
                <SectionTitle
                    title="Produits"
                    
                />
                {/* End: Heading */}
                <div className="row">
                   <div  className="col-md-1 col-sm-1 "></div>
                    <div className="col-md-12 col-sm-11 about_bottom_warp">
                        <div className="about_list">
                            <p> Depuis sa création, la société iNOV Technologies a travaillé avec ses partenaires autour des problématiques de qualification des données.
                            Du fait de notre activité orientée vers les solutions ERP d’entreprise et l’optimisation de la gestion des données, nous avons été plusieurs fois confrontés à la problématique de gestion et de qualification des flux de données.
                            Nos équipes R&D ont travaillé alors sur des outils innovants pour la valorisation et l’intégration des données en entreprise ainsi que sur des outils orientés vers les données biomédicales.
                            </p>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2 col-sm-1 "> </div>
                        {
                            teamData.slice(0, 2).map(team => (
                                <TeamList
                                    key={team.id} 
                                    id={team.id}
                                    img={team.img}
                                    title={team.title}
                                />
                            ))
                        }

                    </div>
                
            </div>

        </section>
    );
}

export default Services;