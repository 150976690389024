import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import About from '../components/About/home-one'
import Services from '../components/Services/home-one'
import WhyChooseUs from '../components/WhyChooseUs'
import LogoContainer from '../components/BrandLogo';
import ContactForm from '../components/ContactForm';


const Home = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>iNOV Technologies </title>
        <meta
          name="description"
          content="iNOV Tech"
        />
      </MetaTags>
      <LayoutOne>
        <About />     
        <Services />
        <WhyChooseUs />
        <LogoContainer />
        <ContactForm />
      </LayoutOne>
    </Fragment>
  );
};

export default Home;
