import React, { Fragment } from "react"; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";  
import OffreSingle from "../templates/OffreSingle/index";

const offreePage = () => {
	return (
	  <Fragment>
		<MetaTags>
		  <title>iNOV Technologies</title>
		  <meta
			name="description"
			content="iNOV Technologies"
		  />
		</MetaTags> 
		<LayoutOne>

		    <OffreSingle />

		</LayoutOne>
	  </Fragment>
	);
  };
  
  export default offreePage;



