import React, { useState, useEffect } from "react";
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";

const Footer = () => {

    const [scroll, setScroll] = useState(0);
    const [top, setTop] = useState(0);

    useEffect(() => {
        setTop(100);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        animateScroll.scrollToTop();
    };

    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    return (
        <footer className="footer-section">
            <div className="container">
                <div className="row">

                    <div className="col-lg-4 col-sm-12">
                        <Widget title="Expertises">
                            <List classes="recent-post helpful_post">
                                <LI>
                                    <Link to={process.env.PUBLIC_URL + "/expertise/ERP Cloud?id=1"}>ERP Cloud</Link>
                                </LI>
                                <LI>
                                    <Link to={process.env.PUBLIC_URL + "/expertise/Digitalisation Et DevOps?id=2"}>Digitalisation Et DevOps</Link>
                                </LI>
                                <LI>
                                    <Link to={process.env.PUBLIC_URL + "/expertise/NouvellesTechnologies?id=3"}>Nouvelles Technologies</Link>
                                </LI>
                            </List>
                        </Widget>
                    </div>
                    <div className="col-lg-4 col-sm-12">
                        <Widget title="Produits">
                            <List classes="recent-post helpful_post">
                                <LI>
                                    <Link to={process.env.PUBLIC_URL + "/BIODATA"}>BIODATA</Link>
                                </LI>
                                <LI>
                                    <Link to={process.env.PUBLIC_URL + "/smart data clean"}>SmartDataClean</Link>
                                </LI>
                            </List>
                        </Widget>
                    </div>
                    <div className="col-lg-4 col-sm-12">
                        <Widget title="Contact">
                            <List classes="recent-post helpful_post">
                                <LI>
                                    <div>ADRESSE : 11, Rue tronchet, 75008 Paris  </div>
                                </LI>
                                <LI>
                                    <div >NUMERO : +33 (0)1 48 34 31 97</div>
                                </LI> {/* <p> +33 (0)1 40 07 16 79</p> */}
                                <LI>
                                    <div>FAX : +33 (0)9 70 32 05 19 </div></LI>
                            </List>
                            {/* <p>+33 (0)9 70 32 05 19 </p>  */}
                        </Widget>
                    </div>
                    <div className="subfooter">
                        <div className="copyright_text"> &copy; {new Date().getFullYear()} iNOV-TECHNOLOGIES <Link to={process.env.PUBLIC_URL + "/"}> </Link>
                        </div>
                        <button className={`scrollup ${scroll > top ? "show" : ""}`} onClick={() => scrollToTop()} >
                            <span className="arrow_triangle-up"></span>
                        </button>
                    </div>
                </div>
            </div>

        </footer>
    )
}

export default Footer;