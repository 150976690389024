import React, { Fragment } from "react";
import PageHeader from '../../components/PageHeader';
import ServicesData from '../../data/Services/service-one';
import SectionTitle from "../../components/SectionTitles/SectionTitle";

const ServiceDetails = () => {

	const serviceID = new URLSearchParams(window.location.search).get("id");
	const service = ServicesData.find((serviceItem) => serviceItem.id === parseInt(serviceID));

	return (
		
		<Fragment>
            <PageHeader
				bgImg={require('../../assets/images/header-background-1.jpg')}
                //title={service.title}
                parentMenu="Expertise"
                parentMenuLink="expertise"
			/>
            <section className="about-section" id="about">
                <div className="container">
                <SectionTitle
                                title={service.title}
                            />
                <div className="row">
                    <div className="col-md-8 col-sm-12 about_bottom_warp">
                        <div className="about_list">
                           
                            <p>{service.description} </p>
                        </div>

                    </div>
                    <div className="col-md-4 col-sm-12">
                        <img src={require('../../assets/images/' + service.thumb)} alt="service" />

                    </div>
                </div>
            </div>
            </section>

        </Fragment>
			
	);
};
export default ServiceDetails;



