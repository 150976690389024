import React from 'react';
import SectionTitle from "../../SectionTitles/SectionTitle";
import TeamList from './TeamList'
import teamData from '../../../data/Services/service-one'

function Services({ classes }) {
    return (
        <section className={`team_section ${classes}`} id="team">

            <div className="container">
                {/* Heading */}
                <SectionTitle
                    title="Expertise"
                    parentMenu="Expertise"
                    parentMenuLink="expertise"
                />
                {/* End: Heading */}
                <div className="row">
                    <div className="col-md-12 col-sm-11 about_bottom_warp">
                        <div className="about_list">
                            <p>
                            iNOV est spécialisée dans le service informatique à forte valeur ajoutée.
                            Nous apportons notre expertise et nos conseils aux entreprises et ce dans le but d’établir une relation de confiance avec nos partenaires. Nos experts interviennent sur 4 grandes thématiques: Cloud, Digitalisation & DevOps, Nouvelles technologies et Big Data.
                            </p>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                        {
                            teamData.slice(0, 3).map(team => (
                                <TeamList
                                    key={team.id}
                                    id={team.id}
                                    img={team.img}
                                    title={team.title}
                                />
                            ))
                        }

                    </div>               
            </div>

        </section>

    );
}

export default Services;