import React , {Fragment } from 'react';  
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne"; 
import ContactForm from '../components/ContactForm'

const ContactPage = () => {
    
    return(

        <Fragment>
            <MetaTags>
              <title>iNOV Technologies | Contact </title>
              <meta
                name="description"
                content="iNOV Technologies"
              />
            </MetaTags>

            <LayoutOne>
            
              <ContactForm />
 
            </LayoutOne>
        </Fragment>

      );
  }

     

export default ContactPage;   