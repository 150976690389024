import React from 'react';
import { Link } from "react-router-dom";


const PricingItem = (props) => {
	const serviceURL = `/${props.title.toLowerCase()}`

	return (

		<div className="col-md-4 col-sm-11" style={{ marginTop: '15px' }}>
			<div className="member_warp">
			<div className="member_info">
					<Link to={`${process.env.PUBLIC_URL + serviceURL}`}  >
						<h6> <small> </small> {props.title}</h6>
					</Link>
				</div>
				<div className="member_img">
					<img src={require('../../../assets/images/' + props.img)} alt="service" />
				</div>
			</div>
		</div>
	);
};

export default PricingItem;