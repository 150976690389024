import React, { Fragment, useState } from "react";
import ModalOffre from '../../components/ModalOffre/Form';
import Modal from 'react-bootstrap/Modal'


const ServiceContentWrap = ({ service }) => {
    const [show, setShow] = useState(false);
    const handleToogle = () => setShow(!show);
    
    return (
        <Fragment>
            <div className="col-md-11 col-sm-12 single_service_page_content">
                <div className="single_service_title">
                    <h3>{service.title}</h3>
                    {<p dangerouslySetInnerHTML={{ __html: service.description }} />}
                </div>
                <div className="contact-form">
                    <button type="button" name="envoyer" onClick={handleToogle} className=" submit-button btn btn-chos " value="Submit">Postuler</button>
                </div>
            </div>
            <Modal style={{ marginTop: '50px' }} show={show} onHide={handleToogle} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{service.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid"> <ModalOffre offreName = {service.title} handleToogle={handleToogle} /></Modal.Body>
                
            </Modal>
        </Fragment>
    );
};
export default ServiceContentWrap;